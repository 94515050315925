<template>
  <div :style="{ fontFamily: selectedFont, backgroundColor: selectedBackgroundColor }">
    <!-- Header Section -->
    <div class="header-container">
      <img alt="Vue logo" src="../assets/web_header_cal.png" class="header-image">
    </div>

    <!-- Date Range Picker Dialog -->
    <div v-if="showExportRangeDialog" class="export-range-dialog">
      <h3>Select Date Range</h3>
      <input type="date" v-model="rangeStartDate" />
      <input type="date" v-model="rangeEndDate" />
      <button @click="exportNotesInRange">Export</button>
      <button @click="showExportRangeDialog = false">Cancel</button>
    </div>

    <!-- Popup event editor -->
    <div class="event-edit-overlay" v-if="isEventEditDialogOpen">
      <div class="event-edit-dialog">
        <h2>Edit Event</h2>
        <form @submit.prevent="updateEvent">
          <!-- Title -->
          <div class="form-group">
            <label for="event-title">Title</label>
            <input type="text" id="event-title" v-model="eventData.title" required>
          </div>

          <!-- Start Time -->
          <div class="form-group">
            <label for="event-start-time">Start Time</label>
            <input type="datetime-local" id="event-start-time" v-model="eventData.start" required>
          </div>

          <!-- Duration -->
          <div class="form-group">
            <label for="event-duration">Duration (minutes)</label>
            <input type="number" id="event-duration" v-model="eventData.duration" min="1" required>
          </div>

          <!-- Color Palette and Custom Color Picker -->
          <div class="form-group">
            <label for="event-color-palette">Standard Colors</label>
            <select id="event-color-palette" v-model="eventData.color" @change="colorChanged">
              <option value="">Custom Color...</option>
              <option value="#B22532">Dark Red</option>
              <option value="#0DA400">Dark Green</option>
              <option value="#003EA4">Dark Blue</option>
              <option value="#660BF3">Purple</option>
              <option value="#F3740B">Orange</option>
              <option value="#F1EA02">Yellow</option>
              <option value="#FC4040">Red</option>
              <option value="#40FC4B">Green</option>
              <option value="#38BEF4">Blue</option>
              <option value="#38BEF4">Black</option>
              <!-- Add more predefined colors here -->
            </select>
          </div>
          <div class="form-group" v-if="!eventData.color || eventData.color === ''">
            <label for="event-color">Custom Color</label>
            <input type="color" id="event-color" v-model="eventData.color">
          </div>

          <!-- Recurring Event Options -->
          <div class="form-group">
            <label>
              <input type="checkbox" v-model="eventData.isRecurring">
              Recurring Event
            </label>
            <select v-model="eventData.recurringType" :disabled="!eventData.isRecurring">
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </select>

            <div class="form-group" v-if="eventData.isRecurring">
              <label for="event-recurring-times">Number of Repetitions</label>
              <input type="number" id="event-recurring-times" v-model.number="eventData.recurringTimes" min="1" max="30">
            </div>
          </div>

          <!-- Dialog Buttons -->
          <div class="dialog-buttons">
            <button type="button" class="cancel-button" @click="cancelEdit">Cancel</button>
            <button type="submit" class="update-button">Update</button>
            <button type="button" class="delete-button" @click="deleteEvent">Delete</button>
          </div>
        </form>
      </div>
    </div>

    <!-- Screen Layout -->
    <div class="container">
      <!-- Top Section: Calendar and Events List -->
      <div class="top-section">
        <!-- Calendar Section -->
        <div class="calendar-section" ref="calendar">
          <!-- View Selector -->
          <select v-model="calendarView" @change="updateCalendarView">
            <option value="timeGridDay">Day</option>
            <option value="timeGridWeek">Week</option>
            <option value="dayGridMonth">Month</option>
            <option value="dayGridYear">Year</option>
          </select>
          <!-- Calendar Component -->
          <FullCalendar ref="fullCalendar" :key="calendarKey" :options="calendarOptions" />

        </div>

        <!-- Events List Section -->
          <div ref="eventsSection" class="events-section" @dblclick="toggleEventWindowSize">
            <input type="text" v-model="searchQuery" placeholder="Search events..." class="search-box">

            <div class="events-list" ref="eventsList">
              <!-- Render only filtered events without headers when there's a search query -->
              <template v-if="searchQuery">
                <div v-for="event in filteredEvents" :key="event.id" class="event-item" @click="onEventClick(event)">
                  <div class="event-info">
                    <span class="color-dot" :style="{ backgroundColor: event.color }"></span>
                    <span>{{ formatDateToDDMMYYYY(event.start) }}</span>
                    <span>{{ formatEventTime(event.start) }}</span>
                    <span>{{ calculateEventDuration(event) }} mins</span>
                  </div>
                  <div class="event-title">{{ event.title }}</div>
                </div>
              </template>

              <!-- Render grouped events with headers when there's no search query -->
              <template v-else>
                <div v-for="(events, date) in groupedEvents" :ref="`group-${date}`" :key="date" class="date-group">
                  <div class="date-header">{{ formatDisplayDate(date) }}</div>
                  <div v-for="event in events" :key="event.id" class="event-item" @click="onEventClick(event)">
                    <div class="event-info">
                      <span class="color-dot" :style="{ backgroundColor: event.color }"></span>
                      <span>{{ formatDateToDDMMYYYY(event.start) }}</span>
                      <span>{{ formatEventTime(event.start) }}</span>
                      <span>{{ calculateEventDuration(event) }} mins</span>
                    </div>
                    <div class="event-title">{{ event.title }}</div>
                  </div>
                </div>
              </template>

              <!-- Resize Handle -->
              <div class="resize-handle"
                   @mousedown="startResize"
                   @touchstart="startResize"
                   :style="{ backgroundColor: resizeActive ? 'skyblue' : '#ADFFF3' }"></div>
            </div>
          </div>
        </div>
      </div>

          <div class="notes-wrapper" :style="getNotesWrapperStyle()">

          <!-- Todo Note Container -->
           <div class="note-container todo-note-container" :style="{ order: isSmallScreen && !isTodoFirst ? 2 : 1 }">
              <!-- Sticky Todo Note Header -->
              <div class="sticky-note-header">
                <!-- Header Content -->
                <h3>
                  <span :class="{'red-dot': todoNoteSaveStatus === 'unsaved', 'green-dot': todoNoteSaveStatus === 'saved'}"></span>
                  Todo List for {{ formattedNoteDate }}
                  <button @click="exportNotesToText" class="export-button">Export this Todo</button>
                  <button @click="showExportRangeDialog = true" class="export-range-button">Export Notes Range</button>

                </h3>
                <button @click="saveTodoDebounced(true)" :class="['save-button', todoNoteSaveStatus]">Save</button>
                <button @click="refreshNotes" class="refresh-button">Refresh</button>
                <button @click="showNoteHistory('todoNotes', `${this.user.email}_${formattedDate}`)" class="history-button">History</button>
                <button @click="toggleContentVisibility('todo')" class="hide-button">
                  {{ showTodoContent ? 'Hide' : 'Show' }}
                </button>
                <button @click="decrementDate" class="date-change-button">-1 Day</button>
                <button @click="incrementDate" class="date-change-button">+1 Day</button>
                <button @click="toggleNoteOrder" class="toggle-order-button">&#x2942;</button>

              </div>


              <!-- Todo Note CKEditor -->
               <div v-if="showTodoContent">
                <ckeditor :key="todoKey" :editor="editor" v-model="todoContent" :config="editorConfig" @input="noteContentChanged" @blur="saveNote"></ckeditor>
              </div>
            </div>

            <!-- Day Note Container -->
            <div class="note-container day-note-container" :style="{ order: isSmallScreen && isTodoFirst ? 2 : 1 }">
              <!-- Sticky Day Note Header -->
              <div class="sticky-note-header">
                <!-- Header Content -->
                <h3>
                  <span :class="{'red-dot': dayNoteSaveStatus === 'unsaved', 'green-dot': dayNoteSaveStatus === 'saved'}"></span>
                  Note for {{ formattedNoteDate }}
                  <button @click="exportNotesToText" class="export-button">Export this Note</button>
                  <button @click="showExportRangeDialog = true" class="export-range-button">Export Notes Range</button>
                </h3>
                <button @click="saveNoteDebounced(true)" :class="['save-button', dayNoteSaveStatus]">Save</button>
                <button @click="refreshNotes" class="refresh-button">Refresh</button>
                <button @click="showNoteHistory('dayNotes', `${this.user.email}_${formattedDate}`)" class="history-button">History</button>

                <button @click="toggleContentVisibility('day')" class="hide-button">
                  {{ showDayContent ? 'Hide' : 'Show' }}
                </button>
                <button @click="decrementDate" class="date-change-button">-1 Day</button>
                <button @click="incrementDate" class="date-change-button">+1 Day</button>
                <button @click="toggleNoteOrder" class="toggle-order-button">&#x2943;</button>

              </div>
              <!-- Day Note CKEditor -->
              <!--<ckeditor v-show="showDayContent" :editor="editor" v-model="noteContent" :config="editorConfig" @input="noteContentChanged" @blur="saveNote"></ckeditor>-->
              <div v-if="showDayContent">
                <ckeditor :key="dayKey" :editor="editor" v-model="noteContent" :config="editorConfig" @input="noteContentChanged" @blur="saveNote"></ckeditor>
              </div>
            </div>

            <!-- Daily Insights Note Container -->
            <div class="note-container daily-insights-container" :style="{ order: 3 }">
              <!-- Sticky Daily Insights Note Header -->
              <div class="sticky-note-header">
                <!-- Header Content -->
                <h3>Daily Insights for {{ formattedDate }}</h3>
                <button @click="saveDailyInsights(true)" :class="['save-button', dailyInsightsSaveStatus]">Save</button>
                <button @click="refreshNotes" class="refresh-button">Refresh</button>
                <button @click="toggleContentVisibility('insights')" class="hide-button">
                  {{ showInsightsContent ? 'Hide' : 'Show' }}
                </button>
              </div>
              <!-- Daily Insights CKEditor -->
              <!--<ckeditor v-show="showInsightsContent" :editor="editor" v-model="dailyInsightsContent" :config="editorConfig" @input="noteContentChanged" @blur="saveNote"></ckeditor>-->
              <div v-if="showInsightsContent">
                <ckeditor :key="insightsKey" :editor="editor" v-model="dailyInsightsContent" :config="editorConfig" @input="noteContentChanged" @blur="saveNote"></ckeditor>
              </div>
            </div>
        </div>
      </div>

    <div v-if="isHistoryModalOpen" class="history-modal-overlay">
      <div class="history-modal-dialog">
        <ul>
          <li v-for="entry in noteHistory" :key="entry.id">
              <!--{{ formatHistoryDate(entry.timestamp) }}-->
              {{ entry.timestamp }}
              <button @click="revertToVersion(entry)">Revert</button>
          </li>
      </ul>
        <button @click="isHistoryModalOpen = false" class="history-modal-close-button">Close</button>
      </div>
    </div>



</template>


<script>
import CKEditor from '@ckeditor/ckeditor5-vue';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ClassicEditor from '../assets/ckeditor/ckeditor.js'; // Adjust the path based on where you put the custom build
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import dayjs from 'dayjs';

import { db } from '@/firebaseConfig';
import {
  collection,
  addDoc,
  updateDoc,
  getDocs,
  getDoc,
  setDoc,
  query,
  where,
  doc,
  deleteDoc,
  orderBy
} from 'firebase/firestore';
import { auth } from '@/firebaseConfig';
// Utility function
function getFormattedLocalDateTime() {
  return new Date().toLocaleString('en-AU', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  });
}
function formatInsightsToHTML(insights) {
  // Replace '**' with '<strong>' for bold text
  let formatted = insights.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

  // Split the insights into paragraphs based on new lines
  const paragraphs = formatted.split('\n\n');

  // Process each paragraph
  formatted = paragraphs.map(para => {
    // Check if the paragraph is a list and format it
    if (para.trim().startsWith('- ')) {
      const listItems = para.split('\n')
                            .filter(line => line.trim().startsWith('- '))
                            .map(line => `<li>${line.substring(2)}</li>`)
                            .join('');
      return `<ul>${listItems}</ul>`;
    } else {
      // Wrap non-list paragraphs in <p> tags
      return `<p>${para}</p>`;
    }
  }).join('');

  return formatted;
}
function convertHtmlToPlainText(html) {
  let text = html
    // Replace line breaks and paragraph tags with newline characters
    .replace(/<br\s*\/?>/gi, "\n")
    .replace(/<\/p>/gi, "\n")
    .replace(/<p.*?>/gi, "\n")
    // Replace list tags with appropriate plain text equivalents
    .replace(/<ul.*?>/gi, "\n")
    .replace(/<\/ul>/gi, "\n")
    .replace(/<li.*?>/gi, "- ")
    .replace(/<\/li>/gi, "\n")
    // Replace strong tags with asterisks for emphasis
    .replace(/<strong>(.*?)<\/strong>/gi, "*$1*")
    // Replace anchor tags with plain text links
    .replace(/<a href="(.*?)".*?>(.*?)<\/a>/gi, "$2 ($1)")
    // Replace HTML entities with actual characters
    .replace(/&nbsp;/gi, " ")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    // Remove any remaining HTML tags
    .replace(/<[^>]+>/gi, '')
    .trim();

  // Collapse multiple newlines and spaces
  text = text.replace(/\n\s*\n/g, '\n');
  text = text.replace(/ +/g, ' ');

  return text;
}

export default {
  props: {
    selectedFont: {
      type: String,
      default: 'Arial' // Provide a default value
    },
    selectedBackgroundColor: {
      type: String,
      default: '#95F2FE' // Provide a default background color value
    },
    moreSaturatedBackgroundColor: {
      type: String,
      default: '#95F2FE' // Provide a default background color value
    },
    lessSaturatedBackgroundColor: {
      type: String,
      default: '#95F2FE' // Provide a default background color value
    }
  },
  components: {
    FullCalendar,
    ckeditor: CKEditor.component
  },
  data() {
    return {
      date: new Date(),
      editor: ClassicEditor,
      editorConfig: {
        // Configuration options for CKEditor
        removePlugins: ['ckbox'],
        contentStyle: 'background-color: #000000;',
        toolbar: {
          shouldNotGroupWhenFull: true,
        },
      },
      autoSaveTimer: null,
      saveTimeout: null,
      todoContent: '', // Content of the to-do list
      noteContent: '', // Existing property for day notes
      calendarView: 'dayGridMonth', // Default view
      user: null,
      calendarKey: 0,
      calendarEvents: [],
      selectedDate: new Date(), // Used to track the selected date for the note
      eventsFetched: false, // New property to track if events have been fetched
      lastClickedDate: null,
      isFirstClick: true,
      lastClickedTime: null,
      isFirstTimeClick: true,
      lastClickedEventId: null,
      selectedEvent: null,
      isEventEditDialogOpen: false, // To control the visibility of the dialog
      searchQuery: '', //this is for the event list search
      //formattedDate: '', // Initial value can be an empty string or a default date
      dayNoteSaveStatus: 'saved', // can be 'unsaved', 'saving', or 'saved'
      todoNoteSaveStatus: 'saved',
      noteContentTimestamp: '',
      todoContentTimestamp: '',
      notes: [], // Ensure this is defined if it's used by exportNotesToText
      isHistoryModalOpen: false,
      noteHistory: [],
      currentNoteType: '',
      currentNoteId: '',
      dailyInsightsContent: '',
      dailyInsightsSaveStatus: 'unsaved',
      isTodoFirst: true, // True if Todo is first, false if Day Note is first
      isArrowFlipped: false,
      screenWidth: window.innerWidth,
      instanceId: null,
      // Visibility toggles for each container

      showTodoContent: true,
      todoKey: 0, // Key for forcing re-render
      showDayContent: true,
      dayKey: 0,
      showInsightsContent: true,
      insightsKey: 0,
      eventsSectionHeight: '300px', // Set an initial value for the height
      customEventsSectionHeight: null, // For holding custom height set by resizing
      shouldAdjustHeight: true, // Flag to indicate when to adjust height
      defaultEventsSectionHeight: '300px', // Default height
      userHasAdjustedHeight: false,
      resizeActive: false, // To indicate when resizing is active
      isEventWindowExpanded: false, // Tracks if the event window is expanded
      savedScrollPosition: 0, // Initialize a property to hold the saved scroll position

      showExportRangeDialog: false,
      rangeStartDate: '',
      rangeEndDate: '',

      eventData: {
        id: '',
        title: '',
        start: '',
        duration: 30, // Default duration in minutes
        color: '#000000', // Default color
        isRecurring: false,
        recurringType: '',
        repeatCount: 1, // Number of repetitions
        recurringTimes: 1, // Default value
        // Other event-related properties...
      },
    };
  },
  computed: {
    isSmallScreen() {
      return this.screenWidth <= 1000;
    },
    notesWrapperStyle() {
      //console.log("Computed notesWrapperStyle called");

    if (this.isSmallScreen) {
      //console.log("Setting flex-direction:", this.isTodoFirst ? 'row' : 'row-reverse');
      return {}; // No additional styles for small screens
    }
    return { flexDirection: this.isTodoFirst ? 'row' : 'row-reverse' };
  },

    dateHeaderStyle() {
      return `
        .date-header {
          font-weight: bold;
          margin-top: 15px;
          background-color: ${this.moreSaturatedBackgroundColor};
          margin-bottom: 10px;
        }
      `;
    },
     groupedEvents() {
        const groups = {};
        const range = 60; // Assuming 7 days range = I will need to fix the logic later.
        const selectedDate = new Date(this.date);
        selectedDate.setHours(0, 0, 0, 0);

        // Initialize empty arrays for each date in the range
        for (let day = -range; day <= range; day++) {
          const currentDate = new Date(selectedDate);
          currentDate.setDate(selectedDate.getDate() + day);
          const dateKey = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;
          groups[dateKey] = [];
        }

        // Use filteredEvents to populate the groups
        for (const event of this.filteredEvents) {
          const eventStartDate = new Date(event.start);
          const eventDateKey = `${eventStartDate.getDate().toString().padStart(2, '0')}-${(eventStartDate.getMonth() + 1).toString().padStart(2, '0')}-${eventStartDate.getFullYear()}`;
          if (groups[eventDateKey]) {
            groups[eventDateKey].push(event);
          }
        }

        console.log("Grouped Events:", groups);
        return groups;
      },

    formattedDate() {
      if (!this.date) return '';

      // Compute the formatted date
      const formatted = `${this.date.getDate().toString().padStart(2, '0')}-${(this.date.getMonth() + 1).toString().padStart(2, '0')}-${this.date.getFullYear()}`;

      // Log the formatted date
      //console.log("Grouped - formatted date: ", formatted);

      // Return the formatted date
      return formatted;
    },
    formattedNoteDate() {
        if (!this.date) return '';

        // Define options for displaying the date in a long format including the weekday
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

        // Use toLocaleDateString to format the Date object
        return `${this.date.toLocaleDateString('en-US', options)}`;
      },

    sortedEvents() {
      return this.calendarEvents.slice().sort((a, b) => {
        let dateA, dateB;

        if (a.start && a.start.seconds) {
          dateA = new Date(a.start.seconds * 1000);
        } else if (a.start) {
          dateA = new Date(a.start);
        } else {
          return 1; // Or handle this case as needed
        }

        if (b.start && b.start.seconds) {
          dateB = new Date(b.start.seconds * 1000);
        } else if (b.start) {
          dateB = new Date(b.start);
        } else {
          return -1; // Or handle this case as needed
        }

        return dateA - dateB;
      });

    },
    filteredEvents() {
      let events = this.sortedEvents; // Assume sortedEvents is already sorted

      if (this.searchQuery) {
        events = events.filter(event =>
          event.title.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }
      return events;
    },

    selectedEventDate() {
      return this.selectedEvent ? this.formatDate(this.selectedEvent.start) : '';
    },
    calendarOptions() {
      return {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        calendarKey: 0,
        initialView: this.calendarView,
        //initialView: 'dayGridMonth',
        initialDate: new Date(), // Set today's date as initial
        //initialView: 'timeGridDay',
        titleFormat: { // For the month and year at the top
          month: 'long',
          year: 'numeric',
        },
        dayHeaderContent: (args) => {
          if (args.view.type === 'dayGridMonth') {
            // Month view: show day only
            return args.date.toLocaleDateString('en-GB', { weekday: 'short' });
          } else if (args.view.type === 'timeGridWeek') {
            // Week view: show day and date in MM format
            return `${args.date.toLocaleDateString('en-GB', { weekday: 'short' })} ${args.date.getDate().toString().padStart(2, '0')}/${(args.date.getMonth() + 1).toString().padStart(2, '0')}`;
          } else {
            // Default format for other views
            return args.date.toLocaleDateString('en-GB', { weekday: 'short', day: 'numeric', month: 'numeric' });
          }
        },

        dayHeaderFormat: {weekday: 'short', day: 'numeric', month: 'numeric', omitCommas: true},
        slotLabelInterval: '01:00',
        slotLabelFormat: {hour: '2-digit', minute: '2-digit', hour12: false}, // 24-hour format
        slotDuration: '00:30:00',
        slotMinTime: '05:00:00', // Start time of the time grid
        slotMaxTime: '21:00:00', // End time of the time grid
        selectable: true, // Enable selecting time ranges
        select: this.calendarView === 'timeGridWeek' ? this.handleTimeSelection : null,
        //select: this.handleTimeSelection, // Handle time range selections
        //datesSet: this.handleDatesSet, // Add this line
        datesSet: () => {
          this.applyHighlight(this.date);
          this.handleDatesSet;
        },
        events: this.calendarEvents,
        dateClick: this.handleDateClick,
        eventClick: this.handleEventClick,
        //select: this.handleTimeSlotClick
        views: {
          timeGridDay: {
            slotLabelInterval: '01:00', // 30-minute interval for slot labels
            slotDuration: '00:30:00', // 30-minute duration for each slot
            slotMinTime: '05:00:00', // Start time of the time grid (5 AM)
            slotMaxTime: '21:00:00', // End time of the time grid (9 PM)
          },
          dayGridMonth: { // Adjust for the specific view if needed
            columnHeaderText: function(date) {
              return date.toLocaleDateString('en-GB', { weekday: 'short', day: 'numeric', month: 'numeric' });
            }
          },
        },
      };
    },

  },
  watch: {

    formattedDate(newVal) {
      console.log("Formatted Date Changed:", newVal); // Debugging line
    },
    calendarView(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.calendarKey++; // Force re-render

        // Reset selected date to today if the view changes
        if (['timeGridWeek', 'dayGridMonth', 'dayGridYear', 'timeGridDay'].includes(newVal)) {
          this.selectedDate = new Date();
          //const formattedDate = `${this.selectedDate.getDate().toString().padStart(2, '0')}-${(this.selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${this.selectedDate.getFullYear()}`;
          this.fetchNoteForDate(this.formattedDate);
        }
      }
    },

    user(newVal) {
      if (newVal && !this.eventsFetched) {
        this.fetchEvents();
        this.eventsFetched = true; // Set to true after fetching
        this.fetchDailyInsightsForDate(this.formattedDate);

      }
    },
    date(newVal, oldVal) {
      if (newVal !== oldVal) {
        // Format the new date
        const formattedDate = `${newVal.getDate().toString().padStart(2, '0')}-${(newVal.getMonth() + 1).toString().padStart(2, '0')}-${newVal.getFullYear()}`;

        // Fetch notes for the new date
        this.fetchNoteForDate(formattedDate);

        // Scroll to the first event of the new date
        this.scrollToFirstEventOfDate(formattedDate);
      }
        this.enableTodayButtonIfNeeded(newVal);

    },

    'eventData.isRecurring'(newValue) {
      if (newValue) {
        this.eventData.repeatCount = 1; // Reset to 1 when checkbox is checked
      }
    },
    calendarEvents: {
      handler() {
        this.$nextTick(() => {
          this.scrollToTodaysEvent();
        });
      },
      deep: true
    },
    noteContent(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.dayNoteSaveStatus = 'unsaved';
      this.setupAutoSave();
    }
  },
    todoContent(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.todoNoteSaveStatus = 'unsaved';
        this.setupAutoSave();
      }
    },

  },
  mounted() {
    //console.log("Initial Events:", this.groupedEvents); // Debugging line
    this.date = new Date(); // Set to current date on component mount

    this.$nextTick(() => {
      // Adjust the height initially and add an event listener for window resize
      this.adjustEventsSectionHeight();
      window.addEventListener('resize', this.adjustEventsSectionHeight);
    });
    this.fetchNoteForDate(this.formattedDate);
    //window.addEventListener('resize', this.adjustEventsSectionHeight);
    // Add blur event listener for auto-save
    window.addEventListener('blur', this.setupAutoSave);
    this.adjustCalendarContainerHeight();
    window.addEventListener('resize', this.adjustCalendarContainerHeight);
    this.moveCalendarButtons();
    this.updateScreenWidth();
    window.addEventListener('resize', this.updateScreenWidth);
    //console.log("Initial Grouped Events:", this.groupedEvents); // Log on initial mount
    const formattedDate = `${this.date.getDate().toString().padStart(2, '0')}-${(this.date.getMonth() + 1).toString().padStart(2, '0')}-${this.date.getFullYear()}`;
    this.scrollToFirstEventOfDate(formattedDate);
    this.scrollToTodaysEvent();
    // Focus on the calendar section on mount
    this.$refs.calendar.focus();
    //window.scrollTo(0, 0); // Scroll to top

  },

  beforeUnmount() {
    window.removeEventListener('resize', this.adjustEventsSectionHeight);
    window.removeEventListener('resize', this.adjustCalendarContainerHeight);
    window.removeEventListener('resize', this.updateScreenWidth);

  },

  methods: {
    async exportNotesInRange() {
      if (!this.rangeStartDate || !this.rangeEndDate) {
        alert("Please select both start and end dates.");
        return;
      }

      let startDate = dayjs(this.rangeStartDate, "DD-MM-YYYY");
      let endDate = dayjs(this.rangeEndDate, "DD-MM-YYYY");

      let dayNotesAggregated = [];
      let todoNotesAggregated = [];

      while (startDate.isBefore(endDate) || startDate.isSame(endDate, 'day')) {
        const dateString = startDate.format('DD-MM-YYYY');
        const dayNotes = await this.fetchNotesForDate(dateString);
        const todoNotes = await this.fetchTodoNotesForDate(dateString);

        if (dayNotes) {
          dayNotesAggregated.push(`Date: ${dateString}\n${convertHtmlToPlainText(dayNotes)}\n`);
        }
        if (todoNotes) {
          todoNotesAggregated.push(`Date: ${dateString}\n${convertHtmlToPlainText(todoNotes)}\n`);
        }

        startDate = startDate.add(1, 'day');
      }

      // Combine dayNotes and todoNotes into the final formatted text
      const dayNotesSection = `Day Notes:\n${dayNotesAggregated.join('\n')}`;
      const todoNotesSection = `Todo Notes:\n${todoNotesAggregated.join('\n')}`;

      // Final formatting
      const formattedText = `${dayNotesSection}\n${todoNotesSection}`;

      this.downloadFile(formattedText, 'Exported_Notes.txt');
    },

  async fetchNotesForDate(dateString) {
    //console.log(`Fetching day notes for date: ${dateString}`);
    const notesCollectionRef = collection(db, 'dayNotes');
    const q = query(notesCollectionRef, where("date", "==", dateString));

    try {
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
            //console.log(`No day notes found for date: ${dateString}`);
            return "";
        }

        const notes = [];
        querySnapshot.forEach((doc) => {
            notes.push(doc.data().content); // Assuming 'content' holds the note text
        });

        //console.log(`Found ${notes.length} day note(s) for date: ${dateString}`);
        return notes.join("\n"); // Combine multiple notes if necessary
    } catch (error) {
        //console.error(`Error fetching day notes for date ${dateString}:`, error);
        return ""; // Return an empty string or handle the error as needed
    }
  },

  async fetchTodoNotesForDate(dateString) {
    //console.log(`Fetching todo notes for date: ${dateString}`);
    const notesCollectionRef = collection(db, 'todoNotes');
    const q = query(notesCollectionRef, where("date", "==", dateString));

    try {
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
            //console.log(`No todo notes found for date: ${dateString}`);
            return "";
        }

        const notes = [];
        querySnapshot.forEach((doc) => {
            notes.push(doc.data().content); // Assuming 'content' holds the note text
        });

        //console.log(`Found ${notes.length} todo note(s) for date: ${dateString}`);
        return notes.join("\n"); // Combine multiple notes if necessary
    } catch (error) {
        //console.error(`Error fetching todo notes for date ${dateString}:`, error);
        return ""; // Return an empty string or handle the error as needed
    }
  },

  downloadFile(content, fileName) {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
    element.setAttribute('download', fileName);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  },
    saveCurrentScrollPosition() {
      const eventsContainer = this.$refs.eventsSection; // Adjust this to match your events container ref
      if (eventsContainer) {
        this.savedScrollPosition = eventsContainer.scrollTop;
      }
    },

    restoreScrollPosition() {
      const eventsContainer = this.$refs.eventsSection; // Adjust this to match your events container ref
      if (eventsContainer) {
        eventsContainer.scrollTop = this.savedScrollPosition;
      }
    },

    formatDisplayDate(dateString) {
      // Split the dateString into components
      const [day, month, year] = dateString.split('-').map(part => parseInt(part, 10));
      // Adjust month -1 because JavaScript months are 0-indexed
      const date = new Date(year, month - 1, day);

      const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
      return date.toLocaleDateString('en-US', options); // Use 'default' or specific locale as needed
    },

    formatDateToDDMMYYYY(dateString) {
      if (typeof dateString !== 'string') {
        console.error("formatDateToDDMMYYYY expects a string, received:", dateString);
        return ''; // Return an empty string or some default value
      }

      const date = new Date(dateString);
      if (isNaN(date)) {
        console.error("Invalid date string passed to formatDateToDDMMYYYY:", dateString);
        return ''; // Return an empty string or some default value
      }

      return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
    },
    toggleResize() {
      const eventsSection = this.$refs.eventsSection;
      if (eventsSection) {
        if (this.isExpanded) {
          eventsSection.style.height = 'smallHeightValue'; // Set a smaller height
        } else {
          eventsSection.style.height = 'largeHeightValue'; // Set a larger height
        }
        this.isExpanded = !this.isExpanded; // Toggle the state
      }
    },

    toggleEventWindowSize() {
        if (this.isEventWindowExpanded) {
          this.$refs.eventsSection.style.height = '200px'; // Smaller size
        } else {
          this.$refs.eventsSection.style.height = '400px'; // Larger size
        }
        this.isEventWindowExpanded = !this.isEventWindowExpanded;
      },
    // Adjust the date for display in the header
   adjustDateForDisplay(dateKey) {
      //console.log("adjustdatefordisplay: ", dateKey);
      const [year, month, day] = dateKey.split('-');
      return `${day}-${month}-${year}`; // Format as 'dd-mm-yyyy'
    },

    formatHeaderDate(date) {
      const [year, month, day] = date.split("-");
      return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`;
    },
      formatEventStartDate(eventStart) {
        const [year, month, day] = eventStart.split('T')[0].split('-');
        return `${day}-${month}-${year}`;
      },
    scrollToSelectedDateEvents() {
    const eventsListElement = this.$refs.eventsList;
    if (eventsListElement) {
      const selectedDateKey = this.formattedDate;
      const selectedDateSection = eventsListElement.querySelector(`[data-date-key="${selectedDateKey}"]`);

      if (selectedDateSection) {
        selectedDateSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  },
    updateCalendarDate(newDate) {
        if (newDate && newDate instanceof Date && !isNaN(newDate)) {
          const calendarApi = this.$refs.fullCalendar.getApi();
          calendarApi.gotoDate(newDate);
        } else {
          console.error("Invalid date provided:", newDate);
        }
      },
      onUserResize(newHeight) {
        this.handleUserResize();
        this.customEventsSectionHeight = newHeight;
      },

      // Use this computed property to set the height of the events section
      computedEventSectionHeight() {
        return this.customEventsSectionHeight || '300px'; // Replace 'defaultHeightValue' with your default
      },

      adjustEventsSectionHeight() {
        const eventsSectionElement = this.$refs.eventsSection;
        const calendarElement = this.$refs.calendar;

        if (eventsSectionElement) {
          if (window.innerWidth <= 1000) { // Adjust this value based on your responsive breakpoint
            // Set height for smaller screens
            eventsSectionElement.style.height = '300px';
          } else if (calendarElement) {
            // Set height to match calendar height for larger screens
            const calendarHeight = calendarElement.clientHeight;
            eventsSectionElement.style.height = `${calendarHeight}px`;
          }
        }
      },
      // Method to trigger height adjustment when needed
      triggerHeightAdjustment() {
        this.shouldAdjustHeight = true;
        this.adjustEventsSectionHeight();
      },

      startResize(event) {
        this.resizeActive = true; // Indicate resize has started
        //console.log('startResize triggered');

        const clientY = event.type.includes('touch') ? event.touches[0].clientY : event.clientY;
        this.initialY = clientY;
        this.initialHeight = this.$refs.eventsSection.offsetHeight;

        document.addEventListener('mousemove', this.resize);
        document.addEventListener('mouseup', this.stopResize);
        document.addEventListener('touchmove', this.resize, { passive: false });
        document.addEventListener('touchend', this.stopResize);
    },
      resize(event) {
        this.userHasAdjustedHeight = true; // Set flag when user starts resizing
        //console.log('Resizing - userHasAdjustedHeight:', this.userHasAdjustedHeight);

        const clientY = event.type.includes('touch') ? event.touches[0].clientY : event.clientY;
        const deltaY = clientY - this.initialY;
        this.$refs.eventsSection.style.height = `${this.initialHeight + deltaY}px`;
      },

      stopResize() {
        // Update the custom height when resizing stops
        this.customEventsSectionHeight = this.$refs.eventsSection.style.height;

        document.removeEventListener('mousemove', this.resize);
        document.removeEventListener('mouseup', this.stopResize);
        document.removeEventListener('touchmove', this.resize);
        document.removeEventListener('touchend', this.stopResize);

        //console.log('Stop resizing - custom height:', this.customEventsSectionHeight, ' userHasAdjustedHeight:', this.userHasAdjustedHeight);

      },
    handleResize() {
      if (this.$refs.ckeditorInstance) {
        this.$refs.ckeditorInstance.editor.editing.view.change(writer => {
          writer.setStyle('height', 'auto', this.$refs.ckeditorInstance.editor.ui.getEditableElement());
        });
      }
    },

    toggleContentVisibility(contentType) {
      if (contentType === 'todo') {
        this.showTodoContent = !this.showTodoContent;
        this.todoKey++; // Increment key to force re-render
        //console.log('showTodoContent:', this.showTodoContent);
      } else if (contentType === 'day') {
        this.showDayContent = !this.showDayContent;
        this.dayKey++;
        //console.log('showDayContent:', this.showDayContent);
      } else if (contentType === 'insights') {
        this.showInsightsContent = !this.showInsightsContent;
        this.insightsKey++;
        //console.log('showInsightsContent:', this.showInsightsContent);
      }
    },

   incrementDate() {
      let newDate = new Date(this.date);
      newDate.setDate(newDate.getDate() + 1);
      this.date = newDate; // Update this.date

      this.applyDayHighlight(newDate); // Apply highlighting
      this.fetchNoteForDate(this.formattedDate); // Fetch notes for the new date
      this.enableTodayButtonIfNeeded(); // Re-enable Today button if needed
    },

    decrementDate() {
      let newDate = new Date(this.date);
      newDate.setDate(newDate.getDate() - 1);
      this.date = newDate; // Update this.date

      this.applyDayHighlight(newDate); // Apply highlighting
      this.fetchNoteForDate(this.formattedDate); // Fetch notes for the new date
      this.enableTodayButtonIfNeeded(); // Re-enable Today button if needed
    },

    clearDayHighlights() {
      const calendarEl = this.$refs.fullCalendar?.$el;
      const dayElements = calendarEl.querySelectorAll('td[data-date]');
      dayElements.forEach(day => {
        day.style.backgroundColor = ''; // Reset to default
      });
    },

    applyDayHighlight(date) {
    // Ensure 'date' is a Date object
      if (!(date instanceof Date) || isNaN(date.getTime())) {
        console.error('Invalid date provided to applyDayHighlight');
        return;
      }
      this.clearDayHighlights(); // Clear existing highlights first

      // Logic to change the background color of the day cell
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
      const calendarEl = this.$refs.fullCalendar?.$el;
      const dayElement = calendarEl.querySelector(`td[data-date="${formattedDate}"]`);

      if (dayElement) {
        dayElement.style.backgroundColor = '#74EDBF'; // Set your desired color
      }
    },

    async refreshNotes() {
    // Fetch day and todo notes for the selected date
      await this.fetchNoteForDate(this.formattedDate);

      // Fetch daily insights for the selected date
      await this.fetchDailyInsightsForDate(this.formattedDate);
    },

    generateUniqueId() {
      return crypto.randomUUID();
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    toggleNoteOrder() {
      this.isTodoFirst = !this.isTodoFirst;
      this.isArrowFlipped = !this.isArrowFlipped;
      //console.log("Toggle note order clicked, isTodoFirst:", this.isTodoFirst);
    },
    getNotesWrapperStyle() {
      if (this.screenWidth > 1000) {
        return { flexDirection: this.isTodoFirst ? 'row' : 'row-reverse' };
      }
      return {};
    },
    moveCalendarButtons() {
      const calendarEl = this.$refs.fullCalendar?.$el;
      if (calendarEl) {
        //console.log("Calendar element found, proceeding with button setup.");

        // Handling today button
        const todayButton = calendarEl.querySelector('.fc-today-button');
        if (todayButton) {
          todayButton.addEventListener('click', () => this.handleTodayButtonClick());
          this.enableTodayButtonIfNeeded(); // Ensure today button is correctly enabled/disabled
        } else {
          //console.log("Today button not found.");
        }

        // Handling prev button
        const prevButton = calendarEl.querySelector('.fc-prev-button');
        if (prevButton) {
          prevButton.addEventListener('click', () => this.handleArrowButtonClick('prev'));
        }

        // Handling next button
        const nextButton = calendarEl.querySelector('.fc-next-button');
        if (nextButton) {
          nextButton.addEventListener('click', () => this.handleArrowButtonClick('next'));
        }

        // Create a new container and append buttons
        const newContainer = document.createElement('div');
        newContainer.className = 'bottom-buttons-container';
        if (todayButton) newContainer.appendChild(todayButton);
        if (prevButton) newContainer.appendChild(prevButton);
        if (nextButton) newContainer.appendChild(nextButton);
        calendarEl.appendChild(newContainer);

      } else {
        console.log("Calendar element not found.");
      }
    },

     handleTodayButtonClick() {
        this.date = new Date();
        this.updateCalendarForDateChange();
        //console.log("Today button clicked. Current date set to:", this.date);

        // Disable the today button after setting the date to today
        const todayButton = this.$refs.fullCalendar?.$el.querySelector('.fc-today-button');
        if (todayButton) {
          todayButton.setAttribute('disabled', 'disabled');
        }
      },

      handleArrowButtonClick(direction) {
        const calendarApi = this.$refs.fullCalendar.getApi();
        if (direction === 'next') {
          calendarApi.next();
        } else if (direction === 'prev') {
          calendarApi.prev();
        }
        this.date = calendarApi.getDate();
        this.updateCalendarForDateChange();
      },

      enableTodayButtonIfNeeded() {
        const todayButton = this.$refs.fullCalendar?.$el.querySelector('.fc-today-button');
        if (todayButton) {
          const currentDate = this.date;
          const today = new Date();

          // Compare dates without the time part
          const isCurrentDateToday = currentDate.toDateString() === today.toDateString();

          if (!isCurrentDateToday) {
            todayButton.removeAttribute('disabled');
          }
        }
      },

      updateCalendarForDateChange() {
        this.fetchNoteForDate(this.formattedDate);
        this.fetchDailyInsightsForDate(this.formattedDate);
        this.applyDayHighlight(this.date);

        // Only adjust the height if it hasn't been manually adjusted by the user
        if (!this.userHasAdjustedHeight) {
          this.adjustEventsSectionHeight(this.defaultEventsSectionHeight);
        }
      },
      handleUserResize() {
        this.userHasAdjustedHeight = true;
      },

      adjustCalendarContainerHeight() {
        const calendarHeight = this.$refs.fullCalendar.clientHeight; // Adjust the ref to your FullCalendar
        this.$refs.calendar.style.height = `${calendarHeight}px`;
      },
        formatHistoryDate(timestamp) {
            const date = new Date(timestamp);
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear().toString().substr(-2); // Get last two digits of the year
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            return `${day}-${month}-${year} ${hours}:${minutes}`;
        },
        saveNoteDebounced(isManualSave = false) {
          clearTimeout(this.saveTimeout);
          this.saveTimeout = setTimeout(() => this.saveNote(isManualSave), 300); // 300 ms debounce period
        },
        saveTodoDebounced(isManualSave = false) {
          clearTimeout(this.saveTimeout);
          this.saveTimeout = setTimeout(() => this.saveTodo(isManualSave), 300);
        },
        exportNotesToText() {
          if (!this.noteContent && !this.todoContent) {
            console.error("Notes content is not available");
            return;
          }

      const htmlToPlainText = (htmlContent) => {
        // Create a new div element and set its innerHTML to the provided HTML content
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlContent;

        // Convert <br> and <p> tags to line breaks in the plain text
        Array.from(tempDiv.querySelectorAll('br')).forEach(br => br.replaceWith('\n'));
        Array.from(tempDiv.querySelectorAll('p')).forEach(p => p.textContent = p.textContent + '\n\n');

        // Return the text content of the div, which is now the plain text version of the HTML
        return tempDiv.textContent || tempDiv.innerText || '';
      };

      const dayNotePlainText = htmlToPlainText(this.noteContent);
      const todoNotePlainText = htmlToPlainText(this.todoContent);

      let exportedText = 'Day Note:\n' + dayNotePlainText + '\n\n' + 'Todo Note:\n' + todoNotePlainText;

      /*const element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(exportedText));
      element.setAttribute('download', 'notes_export.txt');

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);*/
      // Format current date and time for the filename
      const now = new Date();
      const formattedDate = now.toISOString().replace(/[-:]/g, '').replace(/\..+/, '');
      const filename = `notes_export_${formattedDate}.txt`;

      // Use downloadFile method to download the file
      this.downloadFile(exportedText, filename);
    },

    noteContentChanged() {
      this.dayNoteSaveStatus = 'unsaved';
    },
    setupAutoSave() {
      if (this.autoSaveTimer) {
        clearTimeout(this.autoSaveTimer);
      }

      // Only set up auto-save if there's a significant change
      if (this.noteContent !== this.lastSavedNoteContent) {
        this.autoSaveTimer = setTimeout(() => {
          this.saveNote();
          this.saveTodo();
        }, 5000); // Autosave after 5 seconds of inactivity
      }
    },



    /*formatEventDate(event) {
      const eventDate = new Date(event.start);
      return `${eventDate.getDate().toString().padStart(2, '0')}-${(eventDate.getMonth() + 1).toString().padStart(2, '0')}-${eventDate.getFullYear()}`;
    },*/
    formatEventDate(dateString) {
        const date = new Date(dateString);
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);

        // Adding ordinal suffix to day
        const day = date.getDate();
        let suffix = 'th';
        if (day % 10 === 1 && day !== 11) suffix = 'st';
        else if (day % 10 === 2 && day !== 12) suffix = 'nd';
        else if (day % 10 === 3 && day !== 13) suffix = 'rd';

        // Replacing the day number with day number + suffix
        const dayWithSuffix = formattedDate.replace(day, `${day}${suffix}`);

        return dayWithSuffix;
      },

    findTodaysEventIndex() {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Normalize to the start of the day
      //console.log("Today's normalized date:", today);

      for (let i = 0; i < this.calendarEvents.length; i++) {
        //console.log(`Original Event Date format for index ${i}:`, this.calendarEvents[i].start);

        // Adjust this part based on how your dates are stored
        let eventDate = new Date(this.calendarEvents[i].start);
        eventDate.setHours(0, 0, 0, 0); // Normalize event date

        if (!isNaN(eventDate)) {
          //console.log(`Normalized Event Date for index ${i}:`, eventDate);
          if (eventDate.getTime() === today.getTime()) {
            //console.log(`Today's event found at index ${i}`);
            i = Math.floor(i / 2) - 1; //added this because the index was double
            return i;
          }
        } else {
          //console.log(`Invalid date format at index ${i}`);
        }
      }

      //console.log("Today's event not found");
      return -1;
    },

    scrollToTodaysEvent() {
      const formattedToday = this.formatDateKey(new Date());
      this.scrollToFirstEventOfDate(formattedToday);
    },
    formatDateKey(date) {
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
      },

    formatEventTime(eventStart) {
      const startTime = new Date(eventStart);
      return startTime.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
    },

    calculateEventDuration(event) {
      const start = new Date(event.start);
      const end = new Date(event.end);
      return Math.round((end - start) / 60000); // Duration in minutes
    },

    async onEventClick(event) {
      // Assuming the event date is in a format that can be directly used to create a Date object
      let eventDate = new Date(event.start);

      if (isNaN(eventDate)) {
        console.error('Invalid event date');
        return;
      }

      // Update the date property of the component
      this.date = eventDate;

      // The formattedDate computed property will automatically update based on this.date
      //console.log("onEventClick formatted date: ", this.formattedDate);

      // Fetch the notes for the selected date
      await this.fetchNoteForDate(this.formattedDate);
      // Navigate the calendar to the event's date
      this.navigateToEventDate(event.start);
    },

    selectEvent(event) {
      this.selectedEvent = event;
      this.fetchNoteForDate(event.start);
      // Additional logic to display notes
    },
    handleDateClick(info) {
      // Set the component's date to the clicked date
      this.date = new Date(info.dateStr);

      // Fetch notes and insights for the new date
      this.fetchNoteForDate(this.formattedDate);
      this.fetchDailyInsightsForDate(this.formattedDate);

      // Highlight the clicked date
      this.applyDayHighlight(this.date);

      // Check if this is the first click or a repeated click on the same date
      if (this.lastClickedDate !== info.dateStr) {
        this.isFirstClick = true;
        this.lastClickedDate = info.dateStr;
      }

      if (this.isFirstClick) {
        this.isFirstClick = false;
        // Scroll to the first event of the selected date, if any
        this.scrollToFirstEventOfDate(info.dateStr);
        // After actions are complete, refocus on the calendar
        this.$refs.calendar.focus();
        window.scrollTo(0, 0); // Scroll to top

      } else {
        // Handle the creation of a new event for this date
        this.createEventForDate(info.dateStr);
      }


      // Update grouped events based on the new date
      // This ensures the events list is refreshed to show events starting from this date
      //don't need this as have a computed option for grouped events
      //this.groupedEvents = this.calculateGroupedEvents();
    },

    scrollToFirstEventOfDate(date) {
      this.$nextTick(() => {
        const groupRef = this.$refs[`group-${date}`];
        if (groupRef && groupRef[0]) {
          groupRef[0].scrollIntoView({ behavior: 'smooth' });
        }
      });
    },
    findFirstEventIndex(selectedDate) {
      selectedDate.setHours(0, 0, 0, 0);

      for (let i = 0; i < this.calendarEvents.length; i++) {
        let eventDate = new Date(this.calendarEvents[i].start);
        eventDate.setHours(0, 0, 0, 0);

        if (eventDate.getTime() === selectedDate.getTime()) {
          return i;
        }
      }
      return -1;
    },

    scrollToEvent(index) {
      const eventsListElement = this.$refs.eventsList;
      if (eventsListElement && eventsListElement.children.length > index) {
        const eventItemHeight = eventsListElement.children[index].clientHeight;
        eventsListElement.scrollTop = eventItemHeight * index;
      }
    },
    handleTimeSelection(selectInfo) {
      // Check if the current view is week or day
      const isWeekView = this.calendarView === 'timeGridWeek';

      // Default duration for day view
      let duration = isWeekView ? parseInt(prompt('Enter duration in minutes:', '30'), 10) : 30;
      duration = duration || 30; // Fallback to 30 minutes if no input

      // Get event title
      const title = prompt('Enter event title:') || 'New Event';

      const startDate = selectInfo.start;
      const endDate = new Date(startDate.getTime() + duration * 60000);

      // Format start and end dates
      const startFormatted = startDate.toISOString();
      const endFormatted = endDate.toISOString();

      // Save the event to Firestore
      this.saveEventToFirestore({
        title: title,
        start: startFormatted,
        end: endFormatted,
        userEmail: this.user.email
      });

      // Refresh events
      this.fetchEvents();
    },

    async createEventForDate(dateStr) {
      // Ask user for event name
      const eventName = prompt("Enter event name");
      if (!eventName) return; // Exit if canceled

      const startTime = prompt("Enter start time (HH:mm format)", "09:00");
      if (!startTime) return; // Exit if canceled

      const durationInput = prompt("Enter duration in minutes", "30");
      const duration = parseInt(durationInput, 10);
      if (isNaN(duration)) return; // Exit if canceled or invalid number

      // Calculate end time
      const startDate = new Date(`${dateStr}T${startTime}`);
      const endDate = new Date(startDate.getTime() + duration * 60000);

      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        alert('Invalid date or time');
        return; // Exit if date or time is invalid
      }

      // Format start and end dates
      const startFormatted = startDate.toISOString();
      const endFormatted = endDate.toISOString();

      // Save the event to Firestore
      await this.saveEventToFirestore({
        title: eventName,
        start: startFormatted,
        end: endFormatted,
        userEmail: this.user.email
      });

      // Refresh events
      this.fetchEvents();
    },

    async saveEventToFirestore(event) {
      try {
        await addDoc(collection(db, 'calendarEvents'), event);
        alert("Event saved successfully");
      } catch (error) {
        console.error("Error saving event: ", error);
        alert("Failed to save event");
      }
    },
// Called when an event is clicked
    handleEventClick(clickInfo) {
      this.selectedEvent = clickInfo.event;

      // Extract and format the local start date and time
      let localStart = new Date(this.selectedEvent.start);
      let localStartFormatted = `${localStart.getFullYear()}-${(localStart.getMonth() + 1).toString().padStart(2, '0')}-${localStart.getDate().toString().padStart(2, '0')}T${localStart.getHours().toString().padStart(2, '0')}:${localStart.getMinutes().toString().padStart(2, '0')}`;

      // Calculate the duration in minutes
      let duration = this.selectedEvent.end ? (this.selectedEvent.end - this.selectedEvent.start) / 60000 : 30; // Default to 30 minutes if there's no end time

      this.eventData = {
        id: this.selectedEvent.id,
        title: this.selectedEvent.title,
        start: localStartFormatted,
        duration: duration,
        color: this.selectedEvent.backgroundColor || '#000000',
        // ... other event properties ...
      };

      this.isEventEditDialogOpen = true;
    },
    async createRecurringEvents() {
      if (!this.eventData.recurringTimes || isNaN(this.eventData.recurringTimes)) {
        console.error('Invalid recurring times count');
        return;
      }

      const recurrenceCount = Math.min(this.eventData.recurringTimes, 30); // Limit to 30 recurrences
      const startDate = new Date(this.eventData.start);
      const durationMs = this.eventData.duration * 60000; // Duration in milliseconds

      //console.log(`Creating ${recurrenceCount} recurring events`);

      for (let i = 1; i < recurrenceCount; i++) {
        let newStartDate = new Date(startDate);

        switch (this.eventData.recurringType) {
          case 'daily':
            newStartDate.setDate(newStartDate.getDate() + i);
            break;
          case 'weekly':
            newStartDate.setDate(newStartDate.getDate() + 7 * i);
            break;
          case 'monthly':
            newStartDate.setMonth(newStartDate.getMonth() + i);
            break;
          default:
            break;
        }

        const newEndDate = new Date(newStartDate.getTime() + durationMs);

        const newEvent = {
          title: this.eventData.title,
          start: newStartDate.toISOString(),
          end: newEndDate.toISOString(),
          color: this.eventData.color,
          userEmail: this.user.email,
          // Include other relevant fields
        };

        //console.log(`Creating recurring event ${i}:`, newEvent);

        try {
          await addDoc(collection(db, 'calendarEvents'), newEvent);
          //console.log(`Recurring event ${i} created successfully.`);
        } catch (error) {
          console.error(`Error creating recurring event ${i}:`, error);
        }
      }
// Refresh events
      this.fetchEvents();
      //console.log('Finished creating all recurring events.');
    },

// Called to update the event
    async updateEvent() {
      try {
        const eventRef = doc(db, 'calendarEvents', this.eventData.id);
        const updatedData = {
          title: this.eventData.title,
          start: new Date(this.eventData.start).toISOString(),
          end: new Date(new Date(this.eventData.start).getTime() + this.eventData.duration * 60000).toISOString(),
          color: this.eventData.color,
          // Additional fields...
        };
        await updateDoc(eventRef, updatedData);
        //console.log('Event updated successfully');

        // Update the event in the local calendarEvents array
        this.calendarEvents = this.calendarEvents.map(e => {
          if (e.id === this.eventData.id) {
            return {...e, ...updatedData};
          }
          return e;
        });

        // Check if the event is recurring and create recurring events
        if (this.eventData.isRecurring) {
          //console.log('Creating recurring events');
          await this.createRecurringEvents();
        }

        this.isEventEditDialogOpen = false; // Close the dialog
      } catch (error) {
        console.error('Error updating event:', error);
        alert('Failed to update event');
      }
    },

//this is the new delete event. Check the one below if incorrect.
// Called to delete the event
    async deleteEvent() {
      try {
        const eventRef = doc(db, 'calendarEvents', this.eventData.id);
        await deleteDoc(eventRef);
        //console.log('Event deleted successfully');

        // Remove the event from the local calendarEvents array
        this.calendarEvents = this.calendarEvents.filter(e => e.id !== this.eventData.id);

        this.isEventEditDialogOpen = false; // Close the dialog
      } catch (error) {
        console.error('Error deleting event:', error);
        alert('Failed to delete event');
      }
    },

    // Called to cancel the edit and close the dialog
    cancelEdit() {
      this.isEventEditDialogOpen = false;
    },

    updateCalendarView() {
      // Get the API from the calendar reference
      if (this.$refs.fullCalendar && this.$refs.fullCalendar.getApi) {
        const calendarApi = this.$refs.fullCalendar.getApi();

        // Check if the current date is valid
        if (this.date instanceof Date && !isNaN(this.date)) {
          // Use the existing date
          calendarApi.gotoDate(this.date);

          // Format the date to YYYY-MM-DD
          const formattedDateForHighlight = `${this.date.getFullYear()}-${(this.date.getMonth() + 1).toString().padStart(2, '0')}-${this.date.getDate().toString().padStart(2, '0')}`;

          // Access the calendar element
          const calendarEl = this.$refs.fullCalendar?.$el;

          // Clear existing highlights
          const existingHighlights = calendarEl.querySelectorAll('.highlighted-day');
          existingHighlights.forEach(day => day.classList.remove('highlighted-day'));

          // Highlight the new date
          const dayElement = calendarEl.querySelector(`td[data-date="${formattedDateForHighlight}"]`);
          if (dayElement) {
            dayElement.classList.add('highlighted-day');
          }
        } else {
          console.error('Invalid date encountered in updateCalendarView');
        }
      }
    },

    applyHighlight(date) {
      const formattedDateForHighlight = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
      const calendarEl = this.$refs.fullCalendar?.$el;
      //console.log("applyhighlight" + formattedDateForHighlight)
      // Remove existing highlights
      const existingHighlights = calendarEl.querySelectorAll('.highlighted-day');
      existingHighlights.forEach(day => day.classList.remove('highlighted-day'));

      // Apply new highlight
      const dayElement = calendarEl.querySelector(`td[data-date="${formattedDateForHighlight}"]`);
      if (dayElement) {
        dayElement.classList.add('highlighted-day');
      } else {
        console.error(`No day cell found for date: ${formattedDateForHighlight}`);
      }
    },

    async fetchEvents() {
      if (!this.user) return;
      try {
        const eventsRef = collection(db, 'calendarEvents');
        const q = query(eventsRef, where("userEmail", "==", this.user.email));
        const querySnapshot = await getDocs(q);
        this.calendarEvents = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id
        }));
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    },
    formatDate(date) {
      if (!date) return '';
      return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
    },

    updateFormattedDate() {
      if (!this.date) return '';

      const day = this.date.getDate().toString().padStart(2, '0');
      const month = (this.date.getMonth() + 1).toString().padStart(2, '0');
      const year = this.date.getFullYear();

      // Return the formatted date instead of setting it
      return `${day}-${month}-${year}`;
    },
    handleDatesSet(info) {
      this.applyDayHighlight(this.date);

      // Check if the current view is the Day view
      if (this.calendarView === 'dayGridDay' || this.calendarView === 'timeGridDay') {
        this.date = new Date(info.startStr);

        //const formattedDate = `${this.date.getDate().toString().padStart(2, '0')}-${(this.date.getMonth() + 1).toString().padStart(2, '0')}-${this.date.getFullYear()}`;
        this.fetchNoteForDate(this.formattedDate); // Fetch the note for the new date
      }
      // Move the calendar buttons
    this.moveCalendarButtons();
    },


    async fetchNoteForDate(date) {
      //console.log("Fetching notes for date:", date);
      if (!this.user || !date) return;

      const noteId = `${this.user.email}_${date}`;

      await this.fetchNote('dayNotes', noteId, 'noteContent');
      await this.fetchNote('todoNotes', noteId, 'todoContent');
    },

    async fetchNote(collectionName, noteId, stateProperty) {
      const noteRef = doc(db, collectionName, noteId);
      try {
        const noteSnap = await getDoc(noteRef);
        if (noteSnap.exists()) {
          this[stateProperty] = noteSnap.data().content;
          this[`${stateProperty}Timestamp`] = noteSnap.data().timestamp || new Date().toISOString();
        } else {
          this[stateProperty] = '';
          this[`${stateProperty}Timestamp`] = new Date().toISOString();
        }
      } catch (error) {
        console.error(`Error fetching ${collectionName.slice(0, -1)}:`, error);
      }
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    navigateToEventDate(date) {
      if (this.$refs.fullCalendar && this.$refs.fullCalendar.getApi) {
        const calendarApi = this.$refs.fullCalendar.getApi();
        calendarApi.gotoDate(date); // Navigate to the date of the event
      }
    },
    async commonSaveSetup(noteType, content, contentTimestamp, isManualSave) {
      if (!this.user) {
        isManualSave && alert('You must be logged in to save notes');
        return { status: 'unsaved', timestamp: contentTimestamp };
      }

      const noteId = `${this.user.email}_${this.formattedDate}`;
      const noteRef = doc(db, noteType, noteId);
      const currentNoteSnap = await getDoc(noteRef);

      const currentInstanceId = this.instanceId;
      //console.log(this.instanceId); // Accessing instanceId correctly using 'this'

      if (currentNoteSnap.exists()) {
        const lastEditedBy = currentNoteSnap.data().lastEditedBy || null;
        if (lastEditedBy && lastEditedBy !== currentInstanceId) {
          //I'm going to leave this out form now.
          //alert(`Warning: This note was last edited in a different environment: ${lastEditedBy}`);
          // Handle the conflict as per your application's logic
        }
      }

      try {
        const newTimestamp = new Date(); // Get current date and time for new timestamp
        await setDoc(noteRef, {
          userEmail: this.user.email,
          date: this.formattedDate,
          content: content,
          timestamp: newTimestamp.toISOString(), // Save in ISO format
          lastEditedBy: currentInstanceId, // Add the current environment's identifier
        });
        return { status: 'saved', timestamp: newTimestamp.toISOString() };
      } catch (error) {
        console.error(`Error saving note:`, error);
        isManualSave && alert(`Failed to save note.`);
        return { status: 'unsaved', timestamp: contentTimestamp };
      }
    },

    async saveNote(isManualSave = false) {
      this.dayNoteSaveStatus = 'saving';
      const { status, timestamp } = await this.commonSaveSetup('dayNotes', this.noteContent, this.noteContentTimestamp, isManualSave);
      this.dayNoteSaveStatus = status;
      this.noteContentTimestamp = timestamp;

      if (status === 'saved') {
        this.noteContentTimestamp = new Date().toISOString();

        // Create history entry only if content has changed significantly or on manual save
        if (isManualSave || (this.noteContent.length > 50 && this.noteContent !== this.lastSavedNoteContent)) {
          await this.createNoteHistoryEntry('dayNotes', `${this.user.email}_${this.formattedDate}`, this.noteContent);
          this.lastSavedNoteContent = this.noteContent; // Update the last saved content
        }
      }
    },

    async saveTodo(isManualSave = false) {
      this.todoNoteSaveStatus = 'saving';
      const { status, timestamp } = await this.commonSaveSetup('todoNotes', this.todoContent, this.todoContentTimestamp, isManualSave);
      this.todoNoteSaveStatus = status;
      this.todoContentTimestamp = timestamp;

      if (status === 'saved') {
        this.todoContentTimestamp = new Date().toISOString();

        // Create history entry only if content has changed significantly or on manual save
        if (isManualSave || (this.todoContent.length > 50 && this.todoContent !== this.lastSavedTodoContent)) {
          await this.createNoteHistoryEntry('todoNotes', `${this.user.email}_${this.formattedDate}`, this.todoContent);
          this.lastSavedTodoContent = this.todoContent; // Update the last saved content
        }
      }
    },
    onFocus() {
      this.isEditing = true;
    },

    onBlur() {
      this.isEditing = false;
      this.setupAutoSave(); // Trigger auto-save only when user stops editing
    },
    async createNoteHistoryEntry(noteType, noteId, content) {
      const historyRef = collection(db, noteType, noteId, 'history');
      const historyTimestamp = getFormattedLocalDateTime();
      try {
        await addDoc(historyRef, {
          content: content,
          //timestamp: new Date().toISOString()
          timestamp: historyTimestamp
        });
        //console.log('Note history entry created successfully for: ', historyTimestamp);
        //console.log('history created - noteid', noteId);
        //console.log('history created - noteType', noteType);
        //console.log('history created - noteType', content);
      } catch (error) {
        //console.error('Error creating note history entry:', error);
      }
    },

    async fetchNoteHistory(noteType, noteId) {
        const historyRef = collection(db, noteType, noteId, 'history');
        try {
            const querySnapshot = await getDocs(query(historyRef, orderBy('timestamp', 'desc')));
            const history = querySnapshot.docs.map(doc => {
                //console.log('history fetched - doc.id', doc.id); // Log inside the map function
                return { id: doc.id, ...doc.data() };
            });
            return history;
        } catch (error) {
            //console.error('Error fetching note history:', error);
            return [];
        }
    },

    async revertToPreviousNoteVersion(noteType, noteId, historyId) {
        //console.log('Reverting to version - noteType:', noteType, 'noteId:', noteId, 'historyId:', historyId);
        const noteRef = doc(db, noteType, noteId);
        const historyRef = doc(db, noteType, noteId, 'history', historyId);

        try {
            const historySnap = await getDoc(historyRef);
            if (historySnap.exists()) {
                await setDoc(noteRef, {
                    content: historySnap.data().content,
                    timestamp: getFormattedLocalDateTime() // Update with the current timestamp
                });
                //console.log('Note reverted to previous version successfully');
            } else {
                //console.log('No history found for the specified version');
            }
        } catch (error) {
            console.error('Error reverting to previous note version:', error);
        }
    },

    async showNoteHistory(noteType, noteId) {
          ////console.log('showNoteHistory called - noteType:', noteType, 'noteId:', noteId);

      this.currentNoteType = noteType;
      this.currentNoteId = noteId;
      this.noteHistory = await this.fetchNoteHistory(noteType, noteId);
      this.isHistoryModalOpen = true;
    },
    async revertToVersion(historyEntry) {
        //console.log('Reverting to version - historyEntry:', historyEntry);
        await this.revertToPreviousNoteVersion(this.currentNoteType, this.currentNoteId, historyEntry.id);
        this.isHistoryModalOpen = false;
        this.fetchNoteForDate(this.formattedDate);
    },
    async checkForUpdates() {
      if (!this.user) return;

      const noteId = `${this.user.email}_${this.formattedDate}`;
      const dayNoteRef = doc(db, 'dayNotes', noteId);
      const todoNoteRef = doc(db, 'todoNotes', noteId);

      try {
        const dayNoteSnap = await getDoc(dayNoteRef);
        const todoNoteSnap = await getDoc(todoNoteRef);

        if (dayNoteSnap.exists() && dayNoteSnap.data().timestamp > this.noteContentTimestamp) {
          // Conflict in day note
          //this.handleConflict('dayNotes', dayNoteSnap.data());
        }

        if (todoNoteSnap.exists() && todoNoteSnap.data().timestamp > this.todoContentTimestamp) {
          // Conflict in todo note
          //this.handleConflict('todoNotes', todoNoteSnap.data());
        }

      } catch (error) {
        console.error('Error checking for updates:', error);
      }
    },
    handleConflict(noteType, newData) {
      //const currentContent = noteType === 'dayNotes' ? this.noteContent : this.todoContent;
      const currentContent = "This is to test current contentto be uncommented later"
      if (confirm(`A new version of this ${noteType === 'dayNotes' ? 'day note' : 'todo note'} is available. Do you want to load it?`)) {
        // Load the new data
        if (noteType === 'dayNotes') {
          this.noteContent = newData.content;
        } else {
          this.todoContent = newData.content;
        }
      } else {
        // Keep the current data, but inform about the conflict
        console.log(`Current ${noteType} content:`, currentContent);
        //console.log(`New ${noteType} content:`, newData.content);
        // You can implement more complex conflict resolution here
      }
    },
    async fetchDailyInsightsForDate(date) {
      if (!this.user || !date) return;

      const insightsId = `${this.user.email}_${date}`;
      console.log('Fetching insights with Id: ', insightsId);

      const insightsRef = doc(db, 'daily_insights', insightsId);
      try {
        const insightsSnap = await getDoc(insightsRef);
        if (insightsSnap.exists()) {
          const rawContent = insightsSnap.data().content;
          this.dailyInsightsContent = formatInsightsToHTML(rawContent);  // Using the standalone function
          // ... handle other data like timestamp if needed ...
        } else {
          this.dailyInsightsContent = '';
        }
      } catch (error) {
        console.error('Error fetching daily insights:', error);
      }
    },

    async saveDailyInsights(isManualSave = false) {
      this.dailyInsightsSaveStatus = 'saving';
      const { status, timestamp } = await this.commonSaveSetup('daily_insights', this.dailyInsightsContent, this.dailyInsightsContentTimestamp, isManualSave);
      this.dailyInsightsSaveStatus = status;
      console.log(`Last modified at: ${timestamp}`);

      // ... handle other logic like updating timestamp, creating history, etc. ...
    },

  },
  beforeUmount() {
    window.removeEventListener('resize', this.adjustEventsSectionHeight);
    // Remove blur event listener
    window.removeEventListener('blur', this.setupAutoSave);
    // Clear the auto-save timer if it's set
    if (this.autoSaveTimer) {
      clearTimeout(this.autoSaveTimer);
    }
      clearInterval(this.updateChecker);

  },

  created() {
    this.instanceId = this.generateUniqueId();
    this.updateChecker = setInterval(this.checkForUpdates, 60000); // Check every 60 seconds
    this.updateScreenWidth();

    //console.log("Created hook called");
    //console.log("User:", this.user);
    //console.log("Formatted Date:", this.formattedDate);

    auth.onAuthStateChanged(user => {
      this.user = user;
      if (user) {
        this.fetchEvents();
        // Format the current date
      const formattedDate = `${this.date.getDate().toString().padStart(2, '0')}-${(this.date.getMonth() + 1).toString().padStart(2, '0')}-${this.date.getFullYear()}`;
      this.fetchNoteForDate(formattedDate); // Fetch note for today's date
    }
    });
  },
};
</script>

<style scoped>
:deep .fc-toolbar-title {
    font-size: 20px; /* Adjust the size as needed */
}
</style>
<style scoped>
:deep(.fc-today-button),
:deep(.fc-prev-button),
:deep(.fc-next-button) {
    font-size: 14px; /* Reduce the font size */
    padding: 5px 10px; /* Adjust padding as needed */
}
.my-custom-buttons-container {
    /* Styles for the new container at the bottom */
    text-align: center;
    padding: 10px;
}
.bottom-buttons-container {
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
  padding: 10px;
}

</style>

<style>
.header-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

}

.header-image {
  max-width: 220%;
  margin-left: 50px;
  height: auto;
  max-height: 100px;
  object-fit: contain;
}

body, html {
  margin: 0;
  padding: 0;
}
.events-section {
  flex: 1; /* 1/3 of the width */
  position: relative;
  border: 1px solid #013220;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  resize: vertical;
  overflow: auto;
  border-radius: 8px;
  background-color: #CEF2FE; /* Lighter shade of blue */
}
.resize-handle {
  width: 100%;
  height: 7px; /* Increased height for easier grabbing */
  background-color: skyblue; /* Consider a more visible color or pattern */
  cursor: ns-resize;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #999; /* Visual indicator */
}

/* Hover effect */
.resize-handle:hover {
  background-color: #aaa; /* Slightly darker on hover */
}

/* Header Styles */
.header-container {
  position: fixed; /* Fixed position at the top */
  top: 0; /* Aligns the header to the top of the viewport */
  left: 0; /* Aligns the header to the left of the viewport */
  right: 0; /* Ensures the header spans the entire width */
  z-index: 1000; /* Keeps the header above other content */

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Full width */
  overflow: hidden; /* This will hide any part of the image that overflows the container */
  background-color: #21244A; /* Replace with your desired color */
}

.header-image {
  max-width: 100%; /* Full width of the container */
  height: auto; /* Maintain aspect ratio */
  max-height: 50px; /* Adjust as needed */
  min-height: 40px; /* Minimum height, adjust this based on the hamburger menu's height */
  object-fit: cover; /* Cover the container while maintaining aspect ratio */
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 10px; /* Adjust the right margin as needed */

  margin-top: 2vh;
}

.top-section {
  display: flex;
  width: 98%;
  margin-right: 10px; /* Adjust the right margin as needed */
  margin-left: 10px; /* Add left margin to prevent cutoff */
  align-items: stretch; /* Align and stretch children vertically */
  /* Removed fixed height to allow dynamic adjustment */
}
.event-edit-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.event-edit-dialog {
  /* Existing styles */
  width: 30%; /* Adjust the width as needed */
  padding: 10px; /* Add padding for spacing */
  margin: 0 auto; /* Center the dialog */
  background-color: #CEF2FE; /* Your light blue background */
  border-radius: 8px; /* Optional: for rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: for subtle shadow */
}

.form-group {
  margin-bottom: 10px;
}
.form-group input[type="text"],
.form-group input[type="datetime-local"],
.form-group input[type="number"],
.form-group select {
  width: 90%; /* Adjust width to be less than 100% */
  padding: 5px; /* Padding inside the input fields */
  border: 1px solid #ccc; /* Border for the input fields */
  border-radius: 4px; /* Rounded corners for the input fields */
  /* Add any additional styling you want for the input fields */
}
.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="datetime-local"],
.form-group input[type="number"],
.form-group select {
  /* Existing styles */
  max-width: 300px; /* Set a max width */

  margin-top: 0; /* Remove top margin from inputs and selects */
  display: block; /* Make sure the fields take the full width of their container */
}
.dialog-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.dialog-buttons button {
  padding: 5px 10px; /* Padding inside the buttons */
  border-radius: 4px; /* Rounded corners for the buttons */
  border: none; /* Remove default border */
  cursor: pointer; /* Change cursor to pointer */
  margin-right: 10px; /* Reduce space between buttons */
  margin-top: 5px; /* Reduce space above buttons */
  /* Add any additional styling you want for the buttons */
}

.cancel-button {
  background-color: #f44336;
  color: white;
}

.update-button {
  background-color: #4caf50;
  color: white;
}

.delete-button {
  background-color: #ffeb3b;
  color: black;
}
.calendar-section {
  flex: 3; /* 2/3 of the width */
  border: 1px solid #013220;
  border-radius: 8px; /* Rounded corners */
  overflow: auto;
}
.highlighted-day {
  background-color: lightgreen; /* Choose a color that stands out */
  /* Additional styles like border, box-shadow, etc. */
}
.search-box {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 2px;
}
.events-list {
  overflow-y: auto;
  flex-grow: 1;
  padding: 0px; /* Minimal padding inside the events list */
}

.event-item {
  border: 1px solid #ddd; /* Change color as needed */
  padding: 5px;
  margin-bottom: 1px;
  line-height: 1.2;
  border-radius: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8em; /* Smaller font size */
}
.event-info {
  display: flex;
  align-items: center;
  gap: 5px;
}
.date-header {
  font-weight: bold;
  margin-top: 15px;
  background-color: #CEF2FE;
  margin-bottom: 10px;
}
.color-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.event-title {
  font-weight: bold;
  margin-top: 2px;
}
.notes-wrapper {
  display: flex;
  flex-wrap: wrap; /* This allows items to wrap to the next line */
  justify-content: space-around;
  margin-top: 10px;
  z-index: 5; /* High z-index to keep it above other content */

}

.note-container {
  flex: 1;
  width: calc(50% - 20px);
  min-width: 300px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #013220; /* Black border */
  background-color: #CEF2FE; /* Lighter shade of blue */
  border-radius: 8px;
  margin: 10px;
  margin-right: 10px; /* Match this with the margin of calendar and events sections */
  margin-left: 10px; /* Consistent left margin */
}

.note-container .ck-editor__top {
  position: sticky;
  top: 50px; /* Replace with the height of your note header */
  z-index: 10; /* Ensure it stays above the content */
}
.daily-insights-container {
  flex: 1 1 100%; /* This will allow the container to grow and take up 100% of the flex container's width */
  width: calc(100% - 20px); /* Adjust the width to account for margins */
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #013220; /* Black border */
  background-color: #CEF2FE; /* Lighter shade of blue */
  border-radius: 8px;
  margin-right: 10px; /* Match this with the margin of calendar and events sections */
  margin-left: 10px; /* Consistent left margin */
  margin-top: 5px;
}
.red-dot {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
.green-dot {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}

.fc-daygrid-day, .fc-timegrid-slot, .fc-timegrid-slot-label {
    background-color: #CEF2FE; /* Light blue background for all date cells */
    /* Other common styles (if any) */
}

/* Highlighting Today's Date */
.fc-daygrid-day.fc-day-today, .fc-timegrid .fc-day-today {
    background-color: #a8d; /* A different shade for today's date */
    color: #333; /* Dark text for contrast */
    border: 1px solid #013220; /* Optional: border for emphasis */
}

/* Highlighting the Selected Date */
.fc-daygrid-day:focus, .fc-timegrid-day:focus {
    background-color: #FFD700; /* Gold background for selected date */
    color: #000000; /* Black text color for better readability */
    border: 2px solid #000000; /* Thicker black border for emphasis */
}

textarea {
  width: 100%; /* Set the width as needed */
  height: 150px; /* Set the height as needed */
  padding: 10px; /* Optional: for internal spacing */
  border: 1px solid #ccc; /* Optional: for border styling */
  border-radius: 4px; /* Optional: for rounded corners */

  /* Add more styling as needed */
}
/* Base styles for larger screens */
.notes-text {
  font-size: 16px; /* or your default size */
  /* other styles */
}
/* Base styles for larger screens */
.fc-toolbar-title {
  font-size: 12px; /* Adjust the size for larger screens */
}

.fc-today-button, .fc-prev-button, .fc-next-button {
  font-size: 14px; /* Adjust the size for larger screens */
}

.save-button {
  background-color: darkred;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
}

.delete-button {
  background-color: darkred;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
}

.save-button.saved {
  background-color: darkgreen;
}
.ck-sticky-panel .ck-sticky-panel__content_sticky {
  top: 50px !important;
}

.sticky-note-header {
    position: sticky;
    top: 35px; /* Adjust as needed */
    background: #CEF2FE; /* Match this to your site's theme */
    padding: 5px;
    z-index: 900; /* Ensure it's above other content */
}
.note-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0; /* Remove bottom margin if any */
  padding: 5px 0; /* Adjust padding as needed */
}
.export-range-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  z-index: 100;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.note-container .export-button {
  background-color: lightskyblue; /* Dark blue background color */
  color: black;
  font-weight: bold; /* Make font bold */
  border: none;
  border-radius: 4px;
  width: 130px;
  padding: 5px 5px;
  cursor: pointer;
  margin: 0 5px; /* Reduced margin between buttons */
}
.export-range-button {
  background-color: lightskyblue; /* Dark blue background color */
  color: black;
  font-weight: bold; /* Make font bold */
  border: none;
  border-radius: 4px;
  width: 150px;
  padding: 5px 5px;
  cursor: pointer;
  margin: 0 5px; /* Reduced margin between buttons */
}
.history-button {
  background-color: darkblue; /* Dark blue background color */
  color: white;
  border: none;
  border-radius: 4px;
  width: 60px;
  padding: 5px 5px;
  cursor: pointer;
  margin: 0 5px; /* Reduced margin between buttons */
}

.toggle-order-button{
  margin-bottom: 10px; /* Space above the day note */
  background-color: orangered; /* Dark blue background color */
  color: white;
  font-weight: bold; /* Make font bold */
  border: none;
  border-radius: 4px;
  padding: 5px 5px;
  cursor: pointer;
  width: 40px;
  margin: 0 5px; /* Reduced margin between buttons */
}
.arrow {
  transform: rotate(180deg);
  font-weight: bold; /* Makes the text bolder */
  font-size: 1.0em; /* Increase the size of the arrow */
  display: inline-block;
  margin: 0 0px; /* Add some space around the arrow */
  transform: scaleX(2.3); /* Makes the arrow wider */
}
.arrow-flipped {
  transform: rotate(180deg);
  font-weight: bold; /* Makes the text bolder */
  font-size: 1.0em; /* Increase the size of the arrow */
  display: inline-block;
  margin: 0 0px; /* Add some space around the arrow */
  transform: scaleX(2.3); /* Makes the arrow wider */
}
.save-button {
  background-color: darkred;
  color: white;
  border: none;
  border-radius: 4px;
  width: 60px;
  padding: 5px 5px;
  cursor: pointer;
  margin: 0 5px; /* Reduced margin between buttons */
}
.refresh-button {
  /* Add styles for your refresh button here */
  background-color: rebeccapurple; /* Example: green background */
  color: white;
  border: none;
  border-radius: 4px;
  width: 60px;
  padding: 5px 5px;
  cursor: pointer;
  margin: 0 5px; /* Reduced margin between buttons */
  }
.hide-button {
  /* Add styles for your hide button here */
  background-color: yellow; /* Example: green background */
  color: black;
  font-weight: bold; /* Make font bold */
  border: none;
  width: 60px;
  border-radius: 4px;
  padding: 5px 5px;
  cursor: pointer;
  margin: 0 5px; /* Reduced margin between buttons */
  }
.date-change-button {
  /* Add styles for your hide button here */
  background-color: lightgreen; /* Example: green background */
  color: black;
  font-weight: bold; /* Make font bold */
  border: none;
  width: 60px;
  border-radius: 4px;
  padding: 5px 5px;
  cursor: pointer;
  margin: 0 5px; /* Reduced margin between buttons */
  }
.history-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1002; /* Ensure it's above other overlays */
}

.history-modal-dialog {
  width: 30%; /* Adjust the width as needed */
  padding: 10px; /* Add padding for spacing */
  margin: 0 auto; /* Center the dialog */
  background-color: #CEF2FE; /* Light blue background */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  overflow-y: auto; /* Allow scrolling for long histories */
  max-height: 80%; /* Limit the maximum height */
}

.history-list {
  list-style-type: none; /* Remove default list styles */
  padding: 0; /* Remove default padding */
}

.history-item {
  margin-bottom: 10px; /* Space between history items */
}

.history-item button {
  padding: 5px 10px; /* Padding inside the buttons */
  border-radius: 4px; /* Rounded corners for the buttons */
  border: none; /* Remove default border */
  cursor: pointer; /* Change cursor to pointer */
  margin-left: 10px; /* Space next to revert button */
  background-color: #4caf50; /* Green background for revert button */
  color: white; /* Text color */
}

.history-modal-close-button {
  padding: 5px 10px; /* Padding inside the button */
  border-radius: 4px; /* Rounded corners for the button */
  border: none; /* Remove default border */
  cursor: pointer; /* Change cursor to pointer */
  background-color: #f44336; /* Red background for close button */
  color: white; /* Text color */
  margin-top: 5px; /* Space above the close button */
  display: block; /* Ensure it takes full width */
  width: 100%;
}

/* Media query for smaller screens (mobile devices) */
@media screen and (max-width: 1000px) {
  .fc-toolbar-title {
    font-size: 14px; /* Adjust for mobile screens */
  }

  .fc-today-button, .fc-prev-button, .fc-next-button {
    font-size: 12px; /* Adjust for mobile screens */
  }

  .notes-wrapper {
    flex-direction: column; /* Stack vertically on smaller screens */
  }

  .note-container, .daily-insights-container {
    width: calc(100% - 35px); /* Adjust width to account for margins */
    margin: 5px; /* Consistent margin for all sides */
  }

  .top-section {
    flex-direction: column; /* Stack calendar and events vertically */
    margin: 0 0px; /* Consistent margin for top section */
  }

  .calendar-section, .events-section {
    flex: none; /* Remove flex sizing */
    width: calc(100% - 2px); /* Adjust width for consistent margins */
    margin: 5px; /* Consistent margin for all sides */
  }

  .events-section {

    overflow-y: auto; /* Add scrollbar for overflow */
    resize: vertical;
    overflow: auto;
    transition: height 0.3s ease;
    }
}

/* Other styles... */
</style>