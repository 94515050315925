<template>
  <div class="hamburger-menu">
    <button @click="toggleMenu" class="hamburger-button">☰</button>
    <div v-if="isMenuOpen" class="dropdown-menu">
      <router-link to="/" @click="closeMenu">Home</router-link>
      <router-link to="/show-flashcards" @click="closeMenu">Flashcards</router-link>
      <router-link to="/edit-flashcard" @click="closeMenu">Edit Flashcard</router-link>
      <router-link to="/csv-manager" @click="closeMenu">CSV Manager</router-link>
      <router-link to="/edit-manual" @click="closeMenu">Edit Manual</router-link>
      <router-link to="/edit-user-manual" @click="closeMenu">User Manual</router-link>
      <router-link to="/edit-admin-manual" @click="closeMenu">Admin Manual</router-link>
      <router-link to="/user-notes" @click="closeMenu">User Notes</router-link>
      <router-link to="/user-calendar" @click="closeMenu">User Calendar</router-link>
      <!-- Add other links as needed -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
  },
};
</script>

<style scoped>
.hamburger-menu {
  /* Styles for the menu container */
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 1050; /* or higher if necessary */

}
</style>
